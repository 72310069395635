import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import styles from './WishList.module.css';
import CardDelete from './CardDelete';
import axios from 'axios';
import Cookies from "js-cookie"; 
import { setUser, removeFromWishlist } from '../Home/authSlice';

export default function WishList() {
  const [cardsData, setCardsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    const fetchWishlist = async () => {
      try {
        const token = Cookies.get('token');
        const response = await axios.get('https://the-ai-rise.onrender.com/api/v1/wishlist', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        console.log(response.data)
        setCardsData(response.data.data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchWishlist();
  }, []);

  const handleDelete = async (id) => {
    try {
      const token = Cookies.get('token');
      await axios.delete(`https://the-ai-rise.onrender.com/api/v1/wishlist/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const updatedCards = cardsData.filter(card => card.id !== id);
      setCardsData(updatedCards);
      dispatch(removeFromWishlist(id)); // Dispatch the action to update the Redux state
    } catch (error) {
      setError(error.message);
    }
  };

  if (loading) {
    return <div className={styles.spinner}></div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <Navbar />
      <div className={styles.wishListContainer}>
        <h2>My Wishlist</h2>
        <div className={styles.toolCards}>
          {cardsData.length > 0 ? (
            cardsData.map((card, index) => (
              <CardDelete
                key={index}
                id={card.id}
                title={card.title}
                icon={card.icon}
                rate={card.rate}
                description={card.cardDescription}
                onDelete={() => handleDelete(card.id)}
              />
            ))
          ) : (
            <div className={styles.empty}>
              <h3>Your Wishlist is Empty</h3>
              <div className={styles.modelViewPort}>
                <div className={styles.eva}>
                  <div className={styles.head}>
                    <div className={styles.eyeChamber}>
                      <div className={styles.eye}></div>
                      <div className={styles.eye}></div>
                    </div>
                  </div>
                  <div className={styles.body}>
                    <div className={styles.hand}></div>
                    <div className={styles.hand}></div>
                    <div className={styles.scannerThing}></div>
                    <div className={styles.scannerOrigin}></div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}
