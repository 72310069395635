import React from 'react'
import styles from './CategoryCard.module.css'
export default function CategoryCard({ name, number,imageUrl}) {
  return (
 <>
<div className={styles.CategoryCard}>
<img src={imageUrl}></img>
<h3>{name}</h3>
<p>{number} tool</p>
</div>

 </>
  )
}
