import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../Home/Home"
import SubmitTool from "../SubmitTool/SubmitTool";
import CategoryPageCard from "../Category/CategoryPageCard";
import ToolDetail from "../Home/ToolDetail";
import ProfilePage from "../Profile/ProfilePage";
import WishList from "../Profile/WishList";
import Blogs from "../Blogs/Blogs";
import BlogDetails from "../Blogs/BlogDetails";
import AdminMain from '../Admin/AdminMain'
import RatePage from '../Home/RatePage';
import ForgotPassword from '../Profile/EmailEnter'; // Ensure this path is correct

const Router = () => {
    return (
        <Routes>
            <Route path="/"   element={<Home />} />
            <Route path="/SubmitTool" element={<SubmitTool />} />
            <Route path="/categories" element={<CategoryPageCard />} />
            {/* <Route path="/ToolDetails" element={<ToolDetail />} /> */}
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/whislist" element={<WishList />} />
            <Route path="/Blogs" element={<Blogs />} />
            <Route path="/blogDetail/:id" element={<BlogDetails />} />
            <Route path="/ToolDetails/:id" element={<ToolDetail />} />
            <Route path="/Admin" element={<AdminMain />} />
            <Route path="/reviews" element={<RatePage />} />
            <Route path="/forgot-password" element={<ForgotPassword />} /> {/* Add this route */}




        </Routes>

    );
};

export default Router;
