import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGithub,
  faXTwitter,
  faInstagram,
  faLinkedin,
  faFacebookF
} from '@fortawesome/free-brands-svg-icons';
import styles from './footer.module.css';
import logo2 from '../images/logo2.png';

const ICONS = [
  { name: 'GitHub', icon: faGithub,iconUrl:'https://www.linkedin.com/in/maxime-yao-b798361b5/' },
  { name: 'Twitter', icon: faXTwitter ,iconUrl:'https://www.linkedin.com/in/maxime-yao-b798361b5/'},
  { name: 'Instagram', icon: faInstagram ,iconUrl:'https://www.linkedin.com/in/maxime-yao-b798361b5/'},
  { name: 'LinkedIn', icon: faLinkedin ,iconUrl:'https://www.linkedin.com/in/maxime-yao-b798361b5/'},
  { name: 'Facebook', icon: faFacebookF ,iconUrl:'https://www.linkedin.com/in/maxime-yao-b798361b5/'}
];

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.logoArea}>
          <img src={logo2} alt="The AIRise Logo" className={styles.logo} />
        </div>
        <div className={styles.socialMedia}>
          {ICONS.map(icon => (
            <a key={icon.name} className={`${styles.socialIcon} ${styles[icon.name.toLowerCase()]}`} href={icon.iconUrl} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={icon.icon} />
            </a>
          ))}
        </div>
        <div className={styles.adDisclosure}>
          <p>
            <span style={{ color:'#17cf97' }}>Advertiser Disclosure:</span> TheAIRise.com is committed to rigorous editorial standards to provide our users with accurate and helpful content. To keep our site free, we may receive compensation when you click some links on our site.
          </p>
        </div>
        <div className={styles.copyRight}>
          © {new Date().getFullYear()} TheAIRise.com. All rights reserved.
        </div>
      </div>
    </footer>
  );
}
