import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import styles from './SideBar.module.css'; // Import the CSS module

export const SidebarData = [
  {
    title: 'Users',
    path: '/Users',
    icon: <FaIcons.FaUsers className={styles.icon} />,
    cName: 'navText' // Make sure this matches a class in your CSS module
  },
  
  {
    title: 'Tools',
    path: '/Tools',
    icon: <FaIcons.FaTools className={styles.icon} />,
    cName: 'navText' // Make sure this matches a class in your CSS module
  },
  {
    title: 'Blogs',
    path: '/Blogs',
    icon: <FaIcons.FaBlog className={styles.icon} />,
    cName: 'navText' // Make sure this matches a class in your CSS module
  },
];
