import React, { useState } from 'react';
import axios from 'axios';
import styles from '../Components/LoginForm.module.css';

export default function EmailEnter({ onEmailSubmit }) {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://the-ai-rise.onrender.com/api/v1/auth/forgotPassword', { email });
      if (response.status === 200) {
        console.log('Success');
        onEmailSubmit(); // Inform parent to switch to code entry view
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        setError(err.response.data.message);
      } else {
        setError('An error occurred. Please try again.');
      }
    }
  };

  return (
    <div className={styles.formContainer} style={{
      width: '350px',
      height: '320px',
      backgroundColor: '#fff',
      boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
      borderRadius: '10px',
      boxSizing: 'border-box',
      padding: '60px 30px'
    }}>
      <p className={styles.title}>Enter your Email</p>
      <form className={styles.form} onSubmit={handleSubmit}>
        <input 
          type="email" 
          className={styles.input} 
          placeholder="Email" 
          onChange={(e) => setEmail(e.target.value)} 
        />
        <button type="submit" className={styles.formBtn}>Reset</button>
      </form>
      {error && <div className="error-message" style={{ color: 'red' }}>{error}</div>}
    </div>
  );
}
