import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Comments from './Comments';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import styles from '../Profile/WishList.module.css';

export default function RatePage({ id, onClose }) {
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupdup, setPopupdup] = useState(false);

  const user = useSelector(state => state.auth.user);
  const loggedIn = useSelector(state => state.auth.loggedIn);

  useEffect(() => {
    if (!loggedIn) {
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
        onClose();
      }, 3000);
      return;
    }

    const fetchComments = async () => {
      const token = Cookies.get('token');
      if (!token) {
        console.error('No token found, redirecting to login...');
        return;
      }

      try {
        const response = await axios.get(`https://the-ai-rise.onrender.com/api/v1/products/${id}/reviews`, {
          headers: { Authorization: `Bearer ${token}` }
        });

        const reviews = response.data.data.map(review => ({
          user: review.user.name,
          photo: review.user.image,
          date: new Date(review.createdAt).toLocaleString(),
          content: review.title,
          rating: review.ratings
        }));
        setComments(reviews);
      } catch (error) {
        console.error('Error fetching comments:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchComments();
  }, [id, loggedIn, onClose]);

  const addComment = async (content, rating) => {
    if (!loggedIn) {
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
      }, 3000);
      return;
    }

    const token = Cookies.get('token');
    if (!token) {
      console.error('No token found, cannot add comment.');
      return;
    }

    const newComment = {
      title: content,
      ratings: rating,
      product: id,
      user: user ? user._id : ''
    };

    try {
      const response = await axios.post('https://the-ai-rise.onrender.com/api/v1/reviews', newComment, {
        headers: { Authorization: `Bearer ${token}` }
      });

      const addedReview = response.data.data;
      const addedComment = {
        user: addedReview.user.name,
        photo: addedReview.user.image,
        date: new Date(addedReview.createdAt).toLocaleString(),
        content: addedReview.title,
        rating: addedReview.ratings
      };
      setComments(prevComments => [...prevComments, addedComment]);
    } catch (error) {
      console.error('Error adding comment:', error);
      setPopupdup(true);
      setTimeout(() => {
        setPopupdup(false);
      }, 3000);
    }
  };

  return (
    <div>
      {showPopup && (
        <div className={styles.popup}>
          You must log in first
        </div>
      )}
      {showPopupdup && (
        <div className={styles.popup}>
          You already rated this tool before
        </div>
      )}
      {loading ? (
        <div className={styles.spinner}></div>
      ) : (
        <Comments comments={comments} onAddComment={addComment} />
      )}
    </div>
  );
}
