import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchCards = createAsyncThunk('cards/fetchCards', async ({ search } = {}) => {
    const query = search ? `&semantic=${search}` : '';
    const response = await axios.get(`https://the-ai-rise.onrender.com/api/v1/products?limit=9${query}`);
    return response.data.data;
});

export const fetchCardById = createAsyncThunk('cards/fetchCardById', async (id, { rejectWithValue }) => {
    if (!id) {
        console.error("Attempted to fetch with undefined ID");
        return rejectWithValue('Invalid ID');
    }
    try {
        const response = await axios.get(`https://the-ai-rise.onrender.com/api/v1/products/${id}`);
        console.log(response.data.data)
        const relatedResponse = await axios.get(`https://the-ai-rise.onrender.com/api/v1/products/${id}?semantic=true&limit=7`);

        return { cardDetail: response.data.data, relatedTools: relatedResponse.data.data.splice(1) };
    } catch (error) {
        console.error("Error fetching card by ID:", error);
        return rejectWithValue(error.message);
    }
});

const cardsSlice = createSlice({
    name: 'cards',
    initialState: {
        cards: [],
        cardDetail: null,
        relatedTools: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCards.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCards.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.cards = action.payload;
            })
            .addCase(fetchCards.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchCardById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCardById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.cardDetail = action.payload.cardDetail;
                state.relatedTools = action.payload.relatedTools;
            })
            .addCase(fetchCardById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default cardsSlice.reducer;