import React, { useState } from 'react';
import axios from 'axios';
import styles from './LoginForm.module.css';
import { setUser } from '../Home/authSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import person from '../images/person.jpg';

import Cookies from "js-cookie";

export default function SignUp({ togglePopup }) {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [error, setError] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate(); 

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== passwordConfirmation) {
      setError('Passwords do not match');
      return;
    }
    try {
      const response = await axios.post('https://the-ai-rise.onrender.com/api/v1/auth/signup', {
        name: username,
        email,
        password,
        passwordConfirmation
      });
      console.log('Sign up successful', response.data);

      const { data, token } = response.data;
      setFormSubmitted(true);
      dispatch(setUser({ 
        email: data.email, 
        photo: data.photo||person, 
        name: data.name, 
        createdAt: data.createdAt, 
        wishlist: data.wishlist 
      }));
      Cookies.set('token', token);
      navigate('/'); // Redirect to home page after login
    } catch (err) {
      console.error('Sign up failed', err);
      setError('Sign up failed. Please try again.');
    }
  };

  return (
    <div className={styles.formContainer}>
      {formSubmitted ? (
        <div className={styles.Message}>
          <div className={styles.success}>
            <div className={styles.successIcon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                viewBox="0 0 24 24"
                height="24"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  fill="#393a37"
                  d="m12 1c-6.075 0-11 4.925-11 11s4.925 11 11 11 11-4.925 11-11-4.925-11-11-11zm4.768 9.14c.0878-.1004.1546-.21726.1966-.34383.0419-.12657.0581-.26026.0477-.39319-.0105-.13293-.0475-.26242-.1087-.38085-.0613-.11844-.1456-.22342-.2481-.30879-.1024-.08536-.2209-.14938-.3484-.18828s-.2616-.0519-.3942-.03823c-.1327.01366-.2612.05372-.3782.1178-.1169.06409-.2198.15091-.3027.25537l-4.3 5.159-2.225-2.226c-.1886-.1822-.4412-.283-.7034-.2807s-.51301.1075-.69842.2929-.29058.4362-.29285.6984c-.00228.2622.09851.5148.28067.7034l3 3c.0983.0982.2159.1748.3454.2251.1295.0502.2681.0729.4069.0665.1387-.0063.2747-.0414.3991-.1032.1244-.0617.2347-.1487.3236-.2554z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <div className={styles.successTitle}>
              Thank you for signing up!
            </div>
          </div>
        </div>
      ) : (
        <>
          <p className={styles.title}>SIGN UP</p>
          <form className={styles.form} onSubmit={handleSubmit}>
            <input
              type="email"
              className={styles.input}
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="text"
              className={styles.input}
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
            <input
              type="password"
              className={styles.input}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <input
              type="password"
              className={styles.input}
              placeholder="Password Confirmation"
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
              required
            />
            <button type="submit" className={styles.formBtn}>Sign up</button>
          </form>
          {error && <p className={styles.error}>{error}</p>}
          <p className={styles.signUpLabel}>
            Already have an account?
            <a className={styles.signUpLink} onClick={() => togglePopup('login')}>
              Log in
            </a>
          </p>
        </>
      )}
    </div>
  );
}
