import React, { useState } from 'react';
import axios from 'axios';
import styles from '../Components/LoginForm.module.css';

export default function EmailEnter() {
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset error state before making a request
    setError('');

    // Ensure email and newPassword are not undefined
    if (email === undefined || newPassword === undefined) {
      setError('Email or new password is undefined');
      return;
    }

    // Ensure email and newPassword are strings
    const emailStr = email.toString();
    const newPasswordStr = newPassword.toString();

    try {
      console.log('Email:', emailStr, 'New Password:', newPasswordStr);
      console.log('Types:', typeof emailStr, typeof newPasswordStr);

      const response = await axios.post('https://the-ai-rise.onrender.com/api/v1/auth/restPassword', { email: emailStr, newPassword: newPasswordStr });
      if (response.status === 200) {
        console.log('Success');
        setFormSubmitted(true);
      }
    } catch (err) {
      // Check if the error response exists and contains a message
      if (err.response && err.response.data && err.response.data.message) {
        setError(err.response.data.message);
      } else {
        setError('An error occurred. Please try again.');
      }
    }
  };

  const successStyle = {
    fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    width: "100%",
    marginTop: "120px",
    padding: "12px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "start",
    background: "#84D65A",
    borderRadius: "8px",
    boxShadow: "0px 0px 5px -3px #111",
  };

  return (
    <div className={styles.formContainer} style={{
      width: '350px',
      height: '400px',
      backgroundColor: '#fff',
      boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
      borderRadius: '10px',
      boxSizing: 'border-box',
      padding: '60px 30px'
    }}>
      {formSubmitted ? (
        <div style={successStyle}>
          <div className={styles.successIcon}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              viewBox="0 0 24 24"
              height="24"
              fill="none"
            >
              <path
                fillRule="evenodd"
                fill="#393a37"
                d="m12 1c-6.075 0-11 4.925-11 11s4.925 11 11 11 11-4.925 11-11-4.925-11-11-11zm4.768 9.14c.0878-.1004.1546-.21726.1966-.34383.0419-.12657.0581-.26026.0477-.39319-.0105-.13293-.0475-.26242-.1087-.38085-.0613-.11844-.1456-.22342-.2481-.30879-.1024-.08536-.2209-.14938-.3484-.18828s-.2616-.0519-.3942-.03823c-.1327.01366-.2612.05372-.3782.1178-.1169.06409-.2198.15091-.3027.25537l-4.3 5.159-2.225-2.226c-.1886-.1822-.4412-.283-.7034-.2807s-.51301.1075-.69842.2929-.29058.4362-.29285.6984c-.00228.2622.09851.5148.28067.7034l3 3c.0983.0982.2159.1748.3454.2251.1295.0502.2681.0729.4069.0665.1387-.0063.2747-.0414.3991-.1032.1244-.0617.2347-.1487.3236-.2554z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
          <p>Password reset successfully!</p>
        </div>
      ) : (
        <>
          <p className={styles.title}>Set your new Password</p>

          
          <form className={styles.form} onSubmit={handleSubmit}>
            <input 
              type="email" 
              className={styles.input} 
              placeholder="Your current email" 
              value={email}
              onChange={(e) => setEmail(e.target.value)} 
            />
            <input 
              type="password" 
              className={styles.input} 
              placeholder="Enter your new password" 
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)} 
            />
            <button type="submit" className={styles.formBtn}>Confirm</button>
          </form>
          {error && <div className="error-message" style={{ color: 'red' }}>{error}</div>}
        </>
      )}
    </div>
  );
}
