import React, { useState, useEffect } from 'react';
import styles from '../Home/ToolDetail.module.css';
import NavBar from '../Components/Navbar';
import BlogCard from '../Components/BlogCard';
import Footer from "../Components/Footer";
import { useParams } from 'react-router-dom';
import axios from 'axios';

export default function BlogDetails() {
    const { id } = useParams();
    const [blog, setBlog] = useState(null);
    const [relatedBlogs, setrelatedBlogs] = useState(null);

    useEffect(() => {
        const fetchBlog = async () => {
            try {
                const response = await axios.get(`https://the-ai-rise.onrender.com/api/v1/blogs/${id}`);
                const response_relate = await axios.get(`https://the-ai-rise.onrender.com/api/v1/blogs`);
                setrelatedBlogs(response_relate.data.data)
                setBlog(response.data.data);
            } catch (error) {
                console.error('Error fetching blog details:', error);
            }
        };

        fetchBlog();
    }, [id]);

    if (!blog) {
        return <div className={styles.spinner}></div>;

    }

    const createMarkup = htmlContent => {
        return { __html: htmlContent };
    };

  

    return (
        <>
            <NavBar />
            <div className={styles.container}>
                <div className={styles.leftSection}>
                    <div className={styles.toolCards}>
                        <h2>Related Blogs</h2>
                        {relatedBlogs.map((card, index) => (
                            <div className={styles.indCard} key={index}>
                                <BlogCard
                                id={card._id}
                                    title={card.title}
                                    author={card.author}
                                    image={card.image}
                                    date={card.date}
                                    category={card.category}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.rightSection}>
                    <div className={styles.rightSectionCont}>
                        <h1>{blog.title}</h1>
                        <div dangerouslySetInnerHTML={createMarkup(blog.content)} />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
