// ProfileCard.js
import React, { useState, useRef } from 'react';
import styles from './ProfileCard.module.css';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../Home/authSlice';
import Cookies from 'js-cookie';
import person from '../images/person.jpg';

const ProfileCard = ({ user }) => {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };


  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    handleUpload(event.target.files[0]);
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const handleUpload = async (file) => {
    if (!file) {
      alert('Please select a file first.');
      return;
    }
  
    const formData = new FormData();
    formData.append('image', file);
    const token = Cookies.get('token'); // Replace 'token' with the name of your token cookie
  
    try {
      const response = await axios.put('https://the-ai-rise.onrender.com/api/v1/users/updateMe', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      });
      console.log(response.data);
      const updatedUser = { ...user, photo: response.data.data.image }; // Assume the response contains the new photo URL
      dispatch(setUser(updatedUser));
      window.location.reload();

      // You can update the user's profile image URL here if needed
    } catch (error) {
      alert('Error uploading profile photo');
      console.error(error);
    }
  };


  return (
    <div className={styles.flipCard}>
      <div className={styles.flipCardInner}>
        <div className={styles.flipCardFront}>
          <div className={styles.profileImage}>
            {/* Ensure you have an image source or handle it accordingly */}
            <img src={user.photo} alt='Profile' />
            <div className={styles.name}>{ user.name}</div>
          </div>
        </div>
        <div className={styles.flipCardBack}>
          <div className={styles.profileDetails}>
            <div className={styles.detail}>
              <strong>Name:</strong>
              <span> {user.name}</span>
            </div>
            <div className={styles.detail}>
              <strong>Email:</strong>
              <span> {user.email}</span>
            </div>

            <div className={styles.detail}>
              <strong>Joined At: </strong>
              <span>{formatDate(user.createdAt)}</span>
            </div>
          </div>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          <button className={styles.editPhotoButton} onClick={triggerFileInput}>
            Upload New Profile Photo
          </button>
        </div>
      </div>
    </div>
  );
}

export default ProfileCard;
