import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './BlogCard.module.css';

export default function BlogCard({ id, title, author, date, image, category }) {
  return (
    <div className={styles.card}>
      <NavLink to={`/blogDetail/${id}`} className={styles.link}>
        <div className={styles.cardImage} style={{ backgroundImage: `url(${image})` }}></div>
        <p className={styles.category}>{category}</p>
        <p className={styles.cardTitle}>{title}</p>
        <p className={styles.cardBody}>Here What In Battlefield Deluxe Hotel Area Edition Nmply Dummy Text</p>
        <p className={styles.footer}>
          Written by <span className={styles.byName}>{author}</span> on <span className={styles.date}>{date}</span>
        </p>
      </NavLink>
    </div>
  );
}
