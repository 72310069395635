import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCardById } from './ToolSlice';
import Navbar from '../Components/Navbar';
import Card from '../Components/Card';
import Footer from '../Components/Footer';
import styles from './ToolDetail.module.css';
import { BiConfused } from "react-icons/bi";
import Popup from 'reactjs-popup';
import RatePage from './RatePage';

const ToolDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { cardDetail, relatedTools } = useSelector((state) => state.cards);
  const [loading, setLoading] = useState(true);
  const [showRatePage, setShowRatePage] = useState(false);

  const handleCloseRatePage = () => {
    setShowRatePage(false);
  };

  useEffect(() => {
    if (id) {
      dispatch(fetchCardById(id))
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    }
  }, [id, dispatch]);

  const openURL = (url) => {
    if (url) {
      window.open(url, '_blank');
    } else {
      alert("The link is not available.");
    }
  };

  if (loading) {
    return (
      <div className={styles.Loading}>
        <div className={styles.spinner}></div>
      </div>
    );
  }

  if (!id) {
    return <div>Loading or Invalid ID...</div>;
  }

  return (
    <>
      <div>
        <Navbar />
      </div>
      <div>
        <Popup
          trigger={
            <div className={styles.reviewsIcon} onClick={() => setShowRatePage(true)}>
<BiConfused />
              <div className={styles.hoverText}>Reviews</div>
            </div>
          }
          open={showRatePage}
          position="right center"
          modal
          overlayStyle={{ background: 'rgba(0,0,0,0.7)' }}
          closeOnDocumentClick
          onClose={handleCloseRatePage}
        >
          <RatePage id={id} onClose={handleCloseRatePage} />
        </Popup>
      </div>
      <div className={styles.container}>
        <div className={styles.leftSection}>
          <div className={styles.toolCards}>
            <h2>Related Tools</h2>
            {relatedTools.map((card, index) => (
              <div key={index} className={styles.indCard}>
                <Card
                  id={card._id}
                  title={card.title}
                  icon={card.icon}
                  rate={card.rate}
                  description={card.cardDescription}
                />
              </div>
            ))}
          </div>
        </div>
        <div className={styles.rightSection}>
          <div className={styles.rightSectionCont}>
            {<img src={cardDetail.attachemtLink} alt="Card Image" />}
            <button className={styles.menubutton} onClick={() => openURL(cardDetail?.URL)}>
              <span>Try it Now !!</span>
            </button>
            <div dangerouslySetInnerHTML={{ __html: cardDetail?.description || "No description available" }} />
          </div>
        </div>

      </div>
      <Footer />


    </>
  );
};

export default ToolDetail;
