import { createSlice } from '@reduxjs/toolkit';
import person from '../images/person.jpg';

const initialState = {
  user: JSON.parse(localStorage.getItem('user')) || { photo: person, name: 'emad', email: '', password: '', wishlist: [] },
  loggedIn: JSON.parse(localStorage.getItem('loggedIn')) || false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
      state.loggedIn = true;
      localStorage.setItem('user', JSON.stringify(state.user));
      localStorage.setItem('loggedIn', JSON.stringify(state.loggedIn));
      console.log('User set:', state.user);
      console.log('Wishlist:', state.user.wishlist); // Console log the wishlist after setting user data
    },
    logoutUser(state) {
      state.user = null;
      state.loggedIn = false;
      localStorage.removeItem('user');
      localStorage.removeItem('loggedIn');
      console.log('User logged out');
    },
    addToWishlist(state, action) {
      if (!state.user.wishlist) {
        state.user.wishlist = [];
      }
      if (!state.user.wishlist.includes(action.payload)) {
        state.user.wishlist.push(action.payload);
        localStorage.setItem('user', JSON.stringify(state.user));
        console.log('Product added to wishlist:', action.payload);
      }
    },
    removeFromWishlist(state, action) {
      if (!state.user.wishlist) {
        state.user.wishlist = [];
      }
      state.user.wishlist = state.user.wishlist.filter(productId => productId !== action.payload);
      localStorage.setItem('user', JSON.stringify(state.user));
      console.log('Product removed from wishlist:', action.payload);
    }
  }
});

export const { setUser, logoutUser, addToWishlist, removeFromWishlist } = authSlice.actions;
export default authSlice.reducer;
