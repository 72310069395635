import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './Navbar.module.css';
import Popup from 'reactjs-popup';
import LoginForm from './LoginForm';
import SignUp from './SignUp';
import EmailEnter from '../Profile/EmailEnter';
import Code from '../Profile/code';
import { NavLink } from 'react-router-dom';
import logo2 from '../images/logo2.png';
import NewPassword from '../Profile/newPassword';

export default function Navbar() {
  const [activeLink, setActiveLink] = useState("Home");
  const user = useSelector(state => state.auth.user);
  const loggedIn = useSelector(state => state.auth.loggedIn);
  const [openLogin, setOpenLogin] = useState(false);
  const [openSignup, setOpenSignup] = useState(false);
  const [openForgotPassword, setOpenForgotPassword] = useState(false);
  const [openCodeEntry, setOpenCodeEntry] = useState(false);
  const [openResetForm, setOpenResetForm] = useState(false);

  const togglePopup = (type) => {
    setOpenLogin(type === 'login');
    setOpenSignup(type === 'signup');
    setOpenForgotPassword(type === 'forget');
    setOpenCodeEntry(type === 'code');
    setOpenResetForm(type === 'reset');
  };

  const handleMove = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <nav className={styles.nav}>
      <img src={logo2} alt='Logo' className={styles.logo} />

      <ul className={styles.navbar}>
        <li>
          <NavLink exact to="/" className={({ isActive }) => isActive ? styles.active : ''} onClick={handleMove}>Home</NavLink>
        </li>
        <li>
          <NavLink to="/categories" className={({ isActive }) => isActive ? styles.active : ''} onClick={handleMove}>AI Tool Categories</NavLink>
        </li>
        <li>
          <NavLink to="/SubmitTool" className={({ isActive }) => isActive ? styles.active : ''} onClick={handleMove}>Submit AI Tool</NavLink>
        </li>
        <li>
          <NavLink to="/Blogs" className={({ isActive }) => isActive ? styles.active : ''} onClick={handleMove}>AI Blog</NavLink>
        </li>
      </ul>
      {loggedIn ? (
        <div className={styles.userArea}>
          <NavLink to="/profile">
            <img src={user.photo} alt="User" className={styles.userPhoto} />
          </NavLink>
          <span className={styles.userName}>{user.name}</span>
        </div>
      ) : (
        <div className={styles.authButtons}>
          <button className={styles.btn} onClick={() => togglePopup('login')}>Login</button>
          <Popup open={openLogin} modal overlayStyle={{ background: 'rgba(0,0,0,0.7)' }} onClose={() => setOpenLogin(false)}>
            <LoginForm togglePopup={togglePopup} />
          </Popup>
          <button className={styles.btn} onClick={() => togglePopup('signup')}>Sign up</button>
          <Popup open={openSignup} modal overlayStyle={{ background: 'rgba(0,0,0,0.7)' }} onClose={() => setOpenSignup(false)}>
            <SignUp togglePopup={togglePopup} />
          </Popup>
          <Popup open={openForgotPassword || openCodeEntry || openResetForm} modal overlayStyle={{ background: 'rgba(0,0,0,0.7)' }} onClose={() => { setOpenForgotPassword(false); setOpenCodeEntry(false); setOpenResetForm(false); }}>
            {openForgotPassword && <EmailEnter onEmailSubmit={() => togglePopup('code')} />}
            {openCodeEntry && <Code onCodeVerify={() => togglePopup('reset')} />}
            {openResetForm && <NewPassword onCodeSubmit={() => togglePopup('forget')} />}
          </Popup>
        </div>
      )}
    </nav>
  );
}
