import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Card.module.css';
import logo from '../images/logo.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { addToWishlist } from '../Home/authSlice'; // Update the path according to your project structure
import axios from 'axios';
import Cookies from "js-cookie"; 

function Card({ id, title, icon, rate, description, liked }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const wishlist = useSelector((state) => state.auth.user?.wishlist || []);
  const loggedIn = useSelector((state) => state.auth.loggedIn); // Check if user is logged in

  // Check if the card is already liked
  const isAlreadyLiked = wishlist.includes(id);
  const [isChecked, setIsChecked] = useState(isAlreadyLiked);
  const [isDisabled, setIsDisabled] = useState(isAlreadyLiked);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    setIsChecked(isAlreadyLiked);
    setIsDisabled(isAlreadyLiked);
  }, [isAlreadyLiked]);

  const handleCheckboxChange = async () => {
    if (!loggedIn) {
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
      }, 3000); // Hide popup after 3 seconds
      return;
    }

    if (!isChecked && !isDisabled) {
      const token = Cookies.get('token');
      try {
        const response = await axios.post('https://the-ai-rise.onrender.com/api/v1/wishlist', {
          productId: id
        }, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (response.status === 200) {
          console.log('Product added to wishlist:', id);
          dispatch(addToWishlist(id));
          setIsChecked(true);
          setIsDisabled(true); // Disable further changes
        } else {
          console.error('Failed to add product to wishlist');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  const handleButtonClick = () => {
    navigate(`/ToolDetails/${id}`);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className={styles.card}>
      {showPopup && (
        <div className={styles.popup}>
          You must log in first
        </div>
      )}
      <img src={logo} alt="Icon" className={styles.icon} />
      <p className={styles.textTitle}>{title}</p>

      <div className={styles.cardDetails}>
        <p className={styles.textBody}>{description}</p>
      </div>

      <div className={styles.fanContainer}>
        <div className={styles.rating}>
          {[...Array(5)].map((_, i) => (
            <label key={i} className={`${styles.star} ${i < rate ? styles.highlight : ''}`}>&#9733;</label>
          ))}
        </div>

        <div>
          <label className={styles.container}>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
              className={styles.input}
              disabled={isDisabled} // Disable the checkbox if the tool is already liked
            />
            <svg
              id="Layer_1"
              version="1.0"
              viewBox="0 0 24 24"
              xmlSpace="preserve"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              className={`${styles.svg} ${isChecked ? styles.svgChecked : styles.svgDefault}`}
            >
              <path d="M16.4,4C14.6,4,13,4.9,12,6.3C11,4.9,9.4,4,7.6,4C4.5,4,2,6.5,2,9.6C2,14,12,22,12,22s10-8,10-12.4C22,6.5,19.5,4,16.4,4z"></path>
            </svg>
          </label>
        </div>

        <span className={styles.span}></span>
      </div>

      <button className={styles.cardButton} onClick={handleButtonClick}>
        More info
      </button>
    </div>
  );
}

export default Card;
