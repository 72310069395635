import React, { useState } from 'react';
import Navbar from '../Components/Navbar';
import styles from './SubmitTool.module.css';
import Footer from '../Components/Footer';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';

export default function SubmitTool() {
  const [value, setValue] = useState('');
  const [isLoading, setIsLoading] = useState(false); // State to manage loading state

  const [formData, setFormData] = useState({
    title: '',
    URL: '',
    keywords: '',
    pricing: 'free',
    attachemtLink: null,
    description: ''

  });
  const [formSubmitted, setFormSubmitted] = useState(false);

  // Handle input changes
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle file input for image
  const handleFileChange = (event) => {
    setFormData((prevState) => ({
      ...prevState,
      image: event.target.files[0],
    }));
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true); // Start loading

    // Create FormData object to handle file uploads
    const data = new FormData();
    console.log(formData.toolName, formData.toolURL,formData.toolTags,formData.pricingModel,value,formData.image)
    data.append('title', formData.toolName);
    data.append('URL', formData.toolURL);
    data.append('keywords', formData.toolTags);
    data.append('pricing', formData.pricingModel);
    data.append('description', value);
    data.append('attachemtLink', formData.image);
    console.log(data);
    try {
      const response = await axios.post('https://the-ai-rise.onrender.com/api/v1/products', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 201) {
        setFormSubmitted(true);
      } else {
        console.error('Form submission failed');
      }
    } catch (error) {
      console.error('Error:', error);
    }

    setIsLoading(false); // Stop loading

  };

  return (
    <>
      <Navbar />
      <div className={styles.mainSec}>
        <h1>Submit A Tool</h1>
        <p>
          We’re always on the lookout for the latest and greatest AI tools to add to our directory...
          However you can fast track the submission, to get in front of customers faster.
        </p>
      </div>

      {formSubmitted ? (
        <div className={styles.Message}>
          <div className={styles.success}>
            <div className={styles.successIcon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                viewBox="0 0 24 24"
                height="24"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  fill="#393a37"
                  d="m12 1c-6.075 0-11 4.925-11 11s4.925 11 11 11 11-4.925 11-11-4.925-11-11-11zm4.768 9.14c.0878-.1004.1546-.21726.1966-.34383.0419-.12657.0581-.26026.0477-.39319-.0105-.13293-.0475-.26242-.1087-.38085-.0613-.11844-.1456-.22342-.2481-.30879-.1024-.08536-.2209-.14938-.3484-.18828s-.2616-.0519-.3942-.03823c-.1327.01366-.2612.05372-.3782.1178-.1169.06409-.2198.15091-.3027.25537l-4.3 5.159-2.225-2.226c-.1886-.1822-.4412-.283-.7034-.2807s-.51301.1075-.69842.2929-.29058.4362-.29285.6984c-.00228.2622.09851.5148.28067.7034l3 3c.0983.0982.2159.1748.3454.2251.1295.0502.2681.0729.4069.0665.1387-.0063.2747-.0414.3991-.1032.1244-.0617.2347-.1487.3236-.2554z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <div className={styles.successTitle}>
              Thank you for submitting your Tool. We may contact you for extra info.
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.container}>
          <form onSubmit={handleSubmit} className={styles.form}>
            <h1>Submit Your Tool</h1>
            <label htmlFor="toolName">Tool Name</label>
            <input
              type="text"
              id="toolName"
              name="toolName"
              value={formData.toolName}
              onChange={handleChange}
              required
              className={styles.input}
            />

            <label htmlFor="toolURL">Tool URL</label>
            <input
              type="url"
              id="toolURL"
              name="toolURL"
              value={formData.toolURL}
              onChange={handleChange}
              required
              className={styles.input}
            />

            <label htmlFor="toolTags">Tool Tags</label>
            <input
              type="text"
              id="toolTags"
              name="toolTags"
              value={formData.toolTags}
              onChange={handleChange}
              required
              className={styles.input}
            />

            <label htmlFor="pricingModel">Pricing Model</label>
            <select
              id="pricingModel"
              name="pricingModel"
              value={formData.pricingModel}
              onChange={handleChange}
              required
              className={styles.select}
            >
              <option value="free">Free</option>
              <option value="freemium">Freemium</option>
              <option value="premium">Premium</option>
              <option value="contact for pricing">Contact for Price</option>
              <option value="deal">DEAL</option>
            </select>

<div className={styles.quill}>
<label htmlFor="description">Tool Description</label>
            <ReactQuill theme="snow" value={value} onChange={setValue} className={styles.customQuill}/>

</div>
          
            <label htmlFor="image">Upload Image</label>
            <input
              type="file"
              id="image"
              name="image"
              onChange={handleFileChange}
              required
              className={styles.input}
            />
            <button type="submit" className={styles.button}>
            {isLoading ? <div className={styles.spinner}></div> : 'Submit'}
            </button>
          </form>
        </div>
      )}

      <Footer />
    </>
  );
}
