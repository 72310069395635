import React, { useState, useEffect, useCallback } from 'react';
import Navbar from '../Components/Navbar';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCards } from './ToolSlice';
import { debounce } from 'lodash';
import styles from './Home.module.css';
import Card from '../Components/Card';
import CategoryCard from '../Components/CategoryCard';
import Footer from '../Components/Footer';
import Cookies from "js-cookie"; 


export default function Home() {
  const [searchValue, setSearchValue] = useState("");
  const [Categories, setCategories] = useState([
    { name: 'Image generators', number: 823, imageUrl: 'logo.jpg' },
    { name: 'Audio Generators', number: 267, imageUrl: 'logo.jpg' },
    { name: 'Text Generators', number: 495, imageUrl: 'logo.jpg' },
    { name: 'Icons maker', number: 391, imageUrl: 'logo.jpg' },
  ]);

  const dispatch = useDispatch();
  const cards = useSelector((state) => state.cards.cards) || [];
  const status = useSelector((state) => state.cards.status);
  const error = useSelector((state) => state.cards.error);
  const wishlist = useSelector((state) => state.auth.user?.wishlist) || [];

  useEffect(() => {
    dispatch(fetchCards());
  }, [dispatch]);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchCards());
    }
  }, [status, dispatch]);

  // Debounced search API call
  const fetchSearchResults = useCallback(
    debounce((searchValue) => {
      if (searchValue) {
        dispatch(fetchCards({ search: searchValue }));
      }
    }, 800),
    [dispatch]
  );

  useEffect(() => {
    if (searchValue === "") {
      dispatch(fetchCards());
    } else {
      fetchSearchResults(searchValue);
    }
  }, [searchValue, fetchSearchResults, dispatch]);

  const handleKeywords = (keyword) => {
    setSearchValue(keyword);
  };

  return (
    <>
      <div>
        <Navbar />
      </div>

      <div className={styles.mainSec}>
        <video autoPlay muted loop className={styles.videoBg}>
          <source src="background2.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className={styles.overlay}></div>

        <h1>Unleash Your Potential With AI</h1>
        <p>We are on a mission to empower those who are only limited by the breadth of their imagination</p>

        <div className={styles.searchDiv}>
          <input
            type="text"
            className={styles.search}
            placeholder="Enter a tool name or use case..."
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
        <div className={styles.keywords}>
          <button className={styles.bottone1} onClick={() => handleKeywords("AI Audio Generators")}><strong>AI Audio Generators</strong></button>
          <button className={styles.bottone1} onClick={() => handleKeywords("AI Code & IT Tools")}><strong>AI Code & IT Tools</strong></button>
          <button className={styles.bottone1} onClick={() => handleKeywords("AI Image Generators")}><strong>AI Image Generators</strong></button>
        </div>
      </div>

      <div className={styles.cont}>
        <div className={styles.toolCards}>
          {status === 'loading' ? (
            <div className={styles.spinner}></div>
          ) : (
            cards.length > 0 && cards.map((card, index) => (
              <Card
                key={index}
                title={card.title}
                icon={card.icon}
                rate={card.ratingsQuantity}
                description={card.cardDescription}
                id={card._id}
                liked={wishlist.includes(card._id)} 
              />
            ))
          )}
        </div>
      </div>

      <div className={styles.CategoriesContainer}>
        <div>
          <h2>AI Tool Categories</h2>
          <p className={styles.categoryP}>We've categorized 3000+ AI tools into 12 categories.</p>
        </div>
        <div className={styles.Categories}>
          {Categories.map((category, index) => (
            <CategoryCard
              key={index}
              name={category.name}
              number={category.number}
              imageUrl={category.imageUrl}
            />
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}
