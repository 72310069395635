import React, { useState } from 'react';
import styles from './CategoryPage.module.css';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import ReactPaginate from 'react-paginate';

export default function CategoryPageCard() {
    const [dataEntries, setDataEntries] = useState([
        {"Category":"Image generators" ,"imageUrl": "logo.jpg", "categories": ["Category 1", "Category 2", "Category 3"] },
        {"Category":"Image generators" , "imageUrl": "logo.jpg", "categories": ["Category 1", "Category 2", "Category 3"] },
        {"Category":"Image generators" , "imageUrl": "logo.jpg", "categories": ["Category 1", "Category 2", "Category 3", "Category 1", "Category 2", "Category 3", "Category 1", "Category 2", "Category 3", "Category 1", "Category 2", "Category 3", "Category 3", "Category 1", "Category 2", "Category 3", "Category 3", "Category 1", "Category 2", "Category 3"] },
        {"Category":"Image generators" , "imageUrl": "logo.jpg", "categories": ["Category 1", "Category 2", "Category 3"] },
        {"Category":"Image generators" , "imageUrl": "logo.jpg", "categories": ["Category 1", "Category 2", "Category 3"] },
        { "Category":"Image generators" ,"imageUrl": "logo.jpg", "categories": ["Category 1", "Category 2", "Category 3"] },
        { "Category":"Image generators" ,"imageUrl": "logo.jpg", "categories": ["Category 1", "Category 2", "Category 3"] },
        {"Category":"Image generators" , "imageUrl": "logo.jpg", "categories": ["Category 1", "Category 2", "Category 3"] },


    ]);

    // Function to toggle the expanded state of a card
    const toggleExpand = index => {
        const newData = [...dataEntries];
        newData[index].expanded = !newData[index].expanded;
        setDataEntries(newData);
    };

    const [currentPage, setCurrentPage] = useState(0);
    const entriesPerPage = 5; // Number of entries per page

    // Function to change the page
    const handlePageClick = (event) => {
        setCurrentPage(event.selected);
        window.scrollTo({ top: 0, behavior: 'smooth' });

    };

    // Calculate the currently displayed entries
    const offset = currentPage * entriesPerPage;
    const currentPageData = dataEntries.slice(offset, offset + entriesPerPage);
    const pageCount = Math.ceil(dataEntries.length / entriesPerPage);

   

    
        return (
            <>
                <div>
                    <Navbar />
                </div>
                <div className={styles.Container}>
                    {currentPageData.map((entry, index) => (  // Changed from dataEntries to currentPageData
                        <div key={index} className={styles.card1}>
                            <div className={styles.photo}>
                                <img src={entry.imageUrl} alt="Descriptive text for the image" />
                            </div>
                            
                            <div className={styles.subcategories}>
                            <div className={styles.subcategoriesHeaderContainer}> 
                <h2 className={styles.subcategoriesHeader}>{entry.Category}</h2>
            </div>                                
            {entry.categories.map((category, catIndex) => (

                                    <div key={catIndex} className={styles.subcategory}>
                                        {category}
                                        
                                    </div>
                                ))}
                                {entry.categories.length > 11 && (
                                    <div className={styles.addContainer}>

                                        <div tabIndex="0" className={styles.plusButton} onClick={() => toggleExpand(index)}>
                                            <svg className={styles.plusIcon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                                                <g mask="url(#mask0_21_345)">
                                                    <path d="M13.75 23.75V16.25H6.25V13.75H13.75V6.25H16.25V13.75H23.75V16.25H16.25V23.75H13.75Z"></path>
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={styles.pagination}
                    activeClassName={styles.active}
                />
                <Footer />
            </>
        );
    }