import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './BlogMainCard.module.css';

export default function BlogMainCard({ id, title, author, date, image, category }) {
  return (
    <div className={styles.card}>
      <NavLink to={`/blogDetail/${id}`} className={styles.link}>
        <div className={styles.cardImage} style={{ backgroundImage: `url(${image})` }}></div>
        <p className={styles.category}>{category}</p>
        <div className={styles.heading}>
          {title}
          <div className={styles.author}>By <span className={styles.name}>{author}</span> {date}</div>
        </div>
      </NavLink>
    </div>
  );
}
