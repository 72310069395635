import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Navbar from '../Components/Navbar';
import ProfileCard from './ProfileCard';
import Footer from '../Components/Footer';
import styles from './ProfilePage.module.css';
import { NavLink } from 'react-router-dom';
import { logoutUser } from '../Home/authSlice'; // Import actions
import Cookies from "js-cookie"; 

export default function ProfilePage() {
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user); // Get user data from Redux store

    const handleLogout = () => {
        dispatch(logoutUser());
        Cookies.remove('token');

    };

    return (
        <>
            <div>
                <Navbar/>
            </div>

            <div className={styles.profileContainer}>
                <ProfileCard user={user} /> {/* Pass user data as props */}
            </div>

            <div className={styles.buttons}>
                <NavLink to="/whislist">
                    <button className={styles.btn}>My Wishlist</button>
                </NavLink>

                <NavLink to="/">
                    <button className={styles.btn} onClick={handleLogout}>Log out</button>
                </NavLink>
            </div>

            <div><Footer/></div>
        </>
    );
}
