import React, { useState, useEffect } from 'react';
import styles from './Blogs.module.css';
import NavBar from '../Components/Navbar';
import Footer from '../Components/Footer';
import BlogCard from '../Components/BlogCard';
import BlogMainCard from '../Components/BlogMainCard';
import axios from 'axios';
import { NavLink } from 'react-router-dom';

export default function Blogs() {
  const [posts, setPosts] = useState([]);
  const [Mainposts, setMainPosts] = useState([]);
  const [TheMain, setTheMain] = useState({});
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://the-ai-rise.onrender.com/api/v1/blogs');
        const data = response.data;
        console.log(data);

        setPosts(data.data);
        const mainPost = data.data[0];
        setTheMain(mainPost);
        console.log(mainPost);

        const mainPosts = data.data.slice(1, 4);
        setMainPosts(mainPosts);
        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error('Error fetching blog posts:', error);
        setLoading(false); // Set loading to false in case of error
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div className={styles.spinner}></div>; // Render spinner while loading
  }

  return (
    <>
      <NavBar />
      <div className={styles.container}>
        <div className={styles.leftSec}>
          {TheMain && (
            <BlogMainCard
              id={TheMain._id}
              title={TheMain.title}
              author={TheMain.author}
              date={TheMain.date}
              image={TheMain.image}
              category={TheMain.category}
            />
          )}
        </div>
        <div className={styles.RightSec}>
          {Mainposts.map((post) => (
            <NavLink to={`/blogDetail/${post._id}`} key={post._id} className={styles.link}>
              <div className={styles.card}>
                <div className={styles.cardImage} style={{ backgroundImage: `url(${post.image})` }}></div>
                <div className={styles.category}>{post.category}</div>
                <div className={styles.heading}>
                  {post.title}
                  <div className={styles.author}>By <span className={styles.name}>{post.author}</span> {post.date}</div>
                </div>
              </div>
            </NavLink>
          ))}
        </div>
      </div>
      <h2 className={styles.headerScd}>NEWS</h2>
      <div className={styles.BlogCardsCont}>
        {posts.map((post) => (
          <BlogCard
            key={post._id}
            id={post._id}
            title={post.title}
            author={post.author}
            date={post.date}
            image={post.image}
            category={post.category}
          />
        ))}
      </div>
      <Footer />
    </>
  );
}
