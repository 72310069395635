import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Router from "./router/router";
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import store from "./redux/store"



const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
       <Provider store={store}>

          <BrowserRouter>
              <Router />
          </BrowserRouter>
          </Provider>
  </React.StrictMode>
);

reportWebVitals();
