import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, TextField, Box, Modal } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import SideBar from '../Components/SideBar';
import styles from './AdminMain.module.css';

const columns = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'username', headerName: 'Username', width: 200 },
  { field: 'email', headerName: 'Email', width: 300 },
];

const initialRows = [
  { id: 1, username: 'JonSnow', email: 'jon.snow@example.com' },
  { id: 2, username: 'CerseiLannister', email: 'cersei.lannister@example.com' },
  { id: 3, username: 'JaimeLannister', email: 'jaime.lannister@example.com' },
  { id: 4, username: 'AryaStark', email: 'arya.stark@example.com' },
  { id: 5, username: 'DaenerysTargaryen', email: 'daenerys.targaryen@example.com' },
  { id: 6, username: 'Melisandre', email: 'melisandre@example.com' },
  { id: 7, username: 'FerraraClifford', email: 'ferrara.clifford@example.com' },
  { id: 8, username: 'RossiniFrances', email: 'rossini.frances@example.com' },
  { id: 9, username: 'HarveyRoxie', email: 'harvey.roxie@example.com' },
];

export default function AdminMain() {
  const [rows, setRows] = React.useState(initialRows);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [newUser, setNewUser] = React.useState({ username: '', email: '' });

  const handleDelete = () => {
    console.log("Deleting rows with IDs:", selectionModel);
    setRows((prevRows) => prevRows.filter((row) => !selectionModel.includes(row.id)));
    setSelectionModel([]);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAdd = () => {
    setRows((prevRows) => [
      ...prevRows,
      { id: uuidv4(), ...newUser },
    ]);
    setNewUser({ username: '', email: '' });
    handleClose();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const user = useSelector((state) => state.auth.user); // Get user data from Redux store
  const [sidebar, setSidebar] = React.useState(false);

  const toggleSidebar = () => setSidebar(!sidebar);

  React.useEffect(() => {
    console.log("Selection model:", selectionModel);
  }, [selectionModel]);

  return (
    <>
      <div>
        <SideBar toggleSidebar={toggleSidebar} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
        <div style={{ height: 600, width: '70%' }}>
          <Box mb={2} display="flex" justifyContent="center" mt={2}>
            <Button
              variant="contained"
              onClick={handleOpen}
              sx={{ backgroundColor: '#17cf97', color: 'white' }}
            >
              Add User
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleDelete}
              disabled={selectionModel.length === 0}
              sx={{
                backgroundColor: selectionModel.length === 0 ? 'grey' : 'red',
                color: 'white',
                marginLeft: '10px',
              }}
            >
              Delete Selected
            </Button>
          </Box>
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[5, 20]}
            checkboxSelection
            onRowSelectionModelChange={(newSelection) => {
              setSelectionModel(newSelection);
            }}
            sx={{
              '& .MuiDataGrid-columnHeaders': {
                fontSize: '1.2rem',
                backgroundColor: '#1b2430',
                color: 'white',
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                color: 'black',
              },
              '& .MuiDataGrid-cell': {
                fontSize: '1.2rem',
              },
              '& .MuiDataGrid-cell--textLeft': {
                whiteSpace: 'normal',
                wordWrap: 'break-word',
              },
            }}
          />
          <Modal open={open} onClose={handleClose}>
            <Box sx={{ p: 4, backgroundColor: 'white', borderRadius: 1, boxShadow: 24 }}>
              <h2>Add New User</h2>
              <TextField
                label="Username"
                name="username"
                value={newUser.username}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Email"
                name="email"
                value={newUser.email}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <Box mt={2}>
                <Button
                  variant="contained"
                  onClick={handleAdd}
                  sx={{ backgroundColor: '#17cf97', color: 'white' }}
                >
                  Add
                </Button>
              </Box>
            </Box>
          </Modal>
        </div>
      </div>
    </>
  );
}
