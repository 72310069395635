import React, { useState } from 'react';
import styles from './Comments.module.css';
import person from '../images/person.jpg';

const Comments = ({ comments, onAddComment }) => {
  const [newComment, setNewComment] = useState('');
  const [newRating, setNewRating] = useState(0);

  const handleInputChange = (event) => {
    setNewComment(event.target.value);
  };

  const handleRatingChange = (event) => {
    setNewRating(parseInt(event.target.value));
  };

  const handleAddComment = () => {
    if (newComment.trim() !== '' && newRating > 0) {
      onAddComment(newComment, newRating);
      setNewComment('');
      setNewRating(0);
    }
  };

  const renderStars = (rating) => {
    return (
      <div className={styles.stars}>
        {[...Array(5)].map((star, index) => (
          <svg
            key={index}
            height="16"
            width="16"
            fill={index < rating ? '#FFD700' : '#D3D3D3'}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M9.049.668L11.18 6.174l5.805.47c.55.044.774.722.373 1.079L13.56 10.64l1.17 5.704c.107.522-.464.92-.92.647L8 14.25l-4.81 2.742c-.455.273-1.027-.126-.92-.647l1.17-5.704-3.798-2.917c-.4-.357-.177-1.035.373-1.079l5.805-.47L6.951.668c.21-.512.888-.512 1.098 0z" />
          </svg>
        ))}
      </div>
    );
  };

  return (
    <div className={styles.card}>
      <span className={styles.title}>Reviews</span>
      {comments.length === 0 ? (
        <p className={styles.NOs}>No reviews yet</p>
      ) : (
        comments.map((comment, index) => (
          <div key={index} className={styles.comments}>
            <div className={styles.commentReact}></div>
            <div className={styles.commentContainer}>
              <div className={styles.user}>
                <div className={styles.userPic}>
                  <img src={comment.photo || person} alt="User" />
                </div>
                <div className={styles.userInfo}>
                  <span>{comment.user}</span>
                  <p>{comment.date}</p>
                  <div className={styles.rating}>{renderStars(comment.rating)}</div>
                </div>
              </div>
              <p className={styles.commentContent}>{comment.content}</p>
            </div>
          </div>
        ))
      )}
      <div className={styles.textBox}>
        <div className={styles.boxContainer}>
          <textarea
            value={newComment}
            onChange={handleInputChange}
            placeholder="Comment "
          ></textarea>
          <div className={styles.inputActions}>
            <select
              className={styles.dropdown}
              value={newRating}
              onChange={handleRatingChange}
            >
              <option value="0">Select rating</option>
              <option value="1">1 Star</option>
              <option value="2">2 Stars</option>
              <option value="3">3 Stars</option>
              <option value="4">4 Stars</option>
              <option value="5">5 Stars</option>
            </select>
            <button
              type="submit"
              className={styles.send}
              title="Send"
              onClick={handleAddComment}
            >
              <svg
                fill="none"
                viewBox="0 0 24 24"
                height="18"
                width="18"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinejoin="round"
                  strokeLinecap="round"
                  strokeWidth="2.5"
                  stroke="#ffffff"
                  d="M12 5L12 20"
                ></path>
                <path
                  strokeLinejoin="round"
                  strokeLinecap="round"
                  strokeWidth="2.5"
                  stroke="#ffffff"
                  d="M7 9L11.2929 4.70711C11.6262 4.37377 11.7929 4.20711 12 4.20711C12.2071 4.20711 12.3738 4.37377 12.7071 4.70711L17 9"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Comments;
