import React, { useState ,useEffect} from 'react';
import axios from 'axios';
import styles from './code.module.css';

const Code = ({onCodeVerify}) => {

    const [time, setTime] = useState(600); // 10 minutes in seconds

    useEffect(() => {
      const timerId = setInterval(() => {
        setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
      }, 1000);
  
      return () => clearInterval(timerId); // Clean up the interval on component unmount
    }, []);
  
    const formatTime = (seconds) => {
      const minutes = Math.floor(seconds / 60);
      const secs = seconds % 60;
      return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };
  
  const [code, setCode] = useState(['', '', '', '', '', '']);
  const [Error, setError] = useState('');

  const handleChange = (index, value) => {
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const codeString = code.join('');
    if (!codeString || codeString.length !== 6) {
      console.error('Code is invalid');
      return;
    }
    try {
      const response = await axios.post('https://the-ai-rise.onrender.com/api/v1/auth/verifyRestCode', {
        "restCode": codeString,
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      console.log(response.status)
      if (response.status === 200) {
        onCodeVerify('reset')
        
        console.log('Code verified successfully');

      } else {
        // Handle error
        console.log('Failed to verify code');
      }
    } catch (error) {
      console.error('Error:', error);
      setError(error)
    }
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.info}>
        <span className={styles.title}>Reset Password</span>
        <p className={styles.description}>
          Enter the two-factor authentication code provided by Email
        </p>
      </div>
      <div style={{ fontSize: '2rem', fontWeight: 'bold' }}>{formatTime(time)}</div>

      <div className={styles.inputFields}>
        {code.map((digit, index) => (
          <input
            key={index}
            placeholder=""
            type="tel"
            maxLength="1"
            value={digit}
            onChange={(e) => handleChange(index, e.target.value)}
          />
        ))}
      </div>

      <div className={styles.actionBtns}>
        <button type="submit" className={styles.verify}>
          Verify
        </button>
      </div>
      {Error && <div className="error-message" style={{ color: 'red' }}>{Error}</div>}

    </form>

  );
};

export default Code;
