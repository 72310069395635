// src/app/store.js
import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../Home/authSlice';
import cardsReducer from '../Home/ToolSlice'; // ensure the path is correct

const store = configureStore({
    reducer: {
        auth: authReducer,
        cards: cardsReducer // adding the cards reducer
    }
});

export default store;
